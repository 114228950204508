/* Footer General */
.custom-footer {
  background-color: #3c3f3f;
  /* Fondo gris oscuro */
  color: #fff7e6;
  /* Texto claro */
  padding: 50px 70px;
  text-align: center;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Estilo del logo y nombre de la empresa */
.footer-logo {
  text-align: center;
  margin-bottom: 20px;
}

.footer-logo .logo {
  max-width: 100px;
}

.footer-logo .icce-name,
.footer-links h3,
.footer-contact h3 {
  font-size: 32px;
  font-weight: normal;
  color: #fff7e6;
  margin-top: 0;
  margin-bottom: 10px;
}

.footer-links h3 {
  text-align: start;
}

/* Estilo de los enlaces a secciones */
.footer-links {
  text-align: center;
  font-size: large;
}

.footer-links ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding-left: 0;
}

.footer-links ul li {
  margin-bottom: 5px;
  font-size: large;
  align-self: flex-start;
}

.footer-links ul li a,
.footer-contact a {
  color: #f4b860;
  /* Amarillo anaranjado pastel */
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: large;
}

.footer-links ul li a:hover,
.footer-contact a:hover {
  color: #ffd591;
  text-decoration: none;
  /* Sin subrayado al hover */
}

/* Estilo de la información de contacto */
.footer-contact {
  text-align: left;
  /* Alineación por defecto a la izquierda */
}

.footer-contact p {
  display: flex;
  align-items: center;
  /* Ícono alineado verticalmente */
  justify-content: flex-start;
  /* Elementos alineados a la izquierda */
  margin: 5px 0;
}

.footer-contact svg {
  margin-right: 8px;
  /* Espacio entre ícono y texto */
}

span.anticon.anticon-right,
span.anticon.anticon-mail,
span.anticon.anticon-environment {
  color: #fff0d2;
  /* Color claro para los íconos */
  font-size: large;
}

p.location-line {
  margin-top: 10px;
}

.footer-contact a.social-link{
  color: #fff0d2;
  font-size: xx-large;
  /* padding-left: 5px; */
  top: 15px;
  position: relative;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .footer-container {
    padding: 0 20px;
    text-align: center;
    /* Centrar el texto de la columna */
  }

  .footer-logo,
  .footer-links {
    text-align: center;
    /* Centrar en pantallas pequeñas */
  }

  .footer-contact {
    text-align: center;
    /* Centrar contenido de contacto en móviles */
  }

  .footer-contact p {
    justify-content: start;
    /* Centramos en pantallas pequeñas */
    padding-left: 13%;
  }

  .footer-logo .icce-name,
  .footer-links h3,
  .footer-contact h3 {
    margin-bottom: 10px;
  }

  .footer-links h3 {
    text-align: center;
  }

  .footer-links ul {
    padding-left: 31%;
  }

  .footer-links ul li {
    margin: 10px 0;
  }

  .footer-contact .location-line a {
    text-align: left;
  }
}