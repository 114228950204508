.contact-us-layout {
    padding: 40px;
}

.contact-us-layout h1 {
    text-align: center;
    color: #610b00;
    margin-bottom: 40px;
    padding-bottom: 8px;
}

.contact-section {
    padding: 20px;
}

.location-line {
    margin: 10px 0;
}

.contact-us-card-container {
    margin-top: 20px;
}

.contact-us-layout a {
    color: #5c0011;
    text-decoration: none;
    transition: color 0.3s ease;
    font-size: x-large;
    line-height: 30px;
}

.contact-us-layout a:hover {
    color: #422509;
    text-decoration: none;
}

.contact-us-layout span.anticon.anticon-right,
.contact-us-layout span.anticon.anticon-mail,
.contact-us-layout span.anticon.anticon-environment,
.contact-us-layout span {
    color: #5c0011;
    font-size: x-large;
    padding-right: 10px;
}

.contact-us-layout .ant-card-head-title {
    text-align: center;
    font-size: xx-large;
}

.contact-us-layout .ant-form-item.submit-button {
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
}

.contact-us-layout button span {
    padding: 0;
    color: white;
}

input.ant-input,
textarea.ant-input {
    border: 1px solid #610b00;
    background-color: #fffbf4;
    border-radius: 2px;
}

input.ant-input:focus,
textarea.ant-input:focus,
input.ant-input:hover,
textarea.ant-input:hover {
    border: 1px solid #610b00;
    background-color: #f3f1ef;
}

.form-contact .ant-input-outlined.ant-input-status-error {
    background-color: #fffbf4;
}

.form-contact .ant-input-outlined.ant-input-status-error:hover, .form-contact input:-webkit-autofill,
.form-contact input:-webkit-autofill:focus {
    background-color: #f3f1ed !important;
}

.form-contact {
    border: 3px solid #eca379 #eca379;
    padding: 20px;
    padding-bottom: 0;
    background-color: #fff7e6;
}

.form-contact-container {
    margin: 40px 0;
    margin-top: 80px;
}

@media (max-width: 767px) {
    .form-contact-container {
        margin: 30px 0;
    }
    .contact-us-layout .ant-card-head-title {
        font-size: x-large;
    }
}