.church-detail {
    display: flex;
    flex-direction: column;
    padding: 20px 80px;
    align-items: center;
    color: #5c0011;
    padding-bottom: 50px;
}

.church-detail p {
    margin-top: 0px;
}

.church-detail h1 {
    text-align: center;
    color: #610b00;
    margin-bottom: 40px;
    padding-bottom: 8px;
    font-size: xx-large;
    /*     border-bottom: 8px #e0aa8b double;
 */
}

.church-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 3%;
}
.church-detail img {
    max-width: 50%;
    max-height: 400px;
    object-fit: cover;
}


.church-section-three {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin-top: 3%;
    gap: 3%;
}

.church-section-three img {
    width: 48%; 
    max-width: 48%; 
    max-height: none;
    object-fit: cover;
    margin-bottom: 3%;
}

.church-contact-info{
    font-size: large;
    width: 45%;
}
.church-information {
    flex-basis: 100%; 
    margin-top: 20px;
}
.church-information.half-width {
    flex-basis: 48%; 
    margin-top: 0; 
}

.church-information.full-width {
    width: 100%;
    /* Ocupará el 100% del ancho */
    padding: 10px;
    /* Agregar un padding para que se vea bien */
}

.divider {
    font-size: 35px;
    position: relative;
    top: -3px;
}


.church-information a {
    color: #5c0011;
    text-decoration: underline;
    transition: color 0.3s ease;
}

.footer-links ul li a:hover,
.church-information a:hover {
    color: #f4b860;
}

.church-information h1 {
    font-size: 36px; /* 2 veces el tamaño base */
    font-weight: bold;
    margin-bottom: 1rem;
}

.church-information h2 {
    font-size: 30px; /* 1.67 veces el tamaño base */
    font-weight: bold;
    margin-bottom: 0.9rem;
}

.church-information h3 {
    font-size: 24px; /* 1.33 veces el tamaño base */
    font-weight: bold;
    margin-bottom: 0.8rem;
}

.church-information h4 {
    font-size: 20px; /* 1.11 veces el tamaño base */
    font-weight: bold;
    margin-bottom: 0.7rem;
}

.church-information h5 {
    font-size: 18px; /* igual al tamaño base */
    font-weight: bold;
    margin-bottom: 0.6rem;
}

.church-information h6 {
    font-size: 16px; /* 0.89 veces el tamaño base */
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.church-information ul{
    margin-block-start: 0px;
    margin-block-end: 0px;
}

@media (max-width: 767px) {
    .church-detail {
        padding: 20px 35px;
    }

    .divider,
    .church-detail .icce-title {
        display: none;
    }

    .church-detail h1 {
        margin-bottom: 20px;
    }

    .church-section-two,
    .church-section-three {
        flex-direction: column;
        padding: 10px;
    }

    .church-detail img {
        width: 100%;
        max-width: 100%;
        height: auto;
        margin-bottom: 20px;
    }

    .church-contact-info,
    .church-information {
        width: 100%;
        padding: 10px;
        font-size: x-large;
    }
    .church-information.half-width{
        flex-basis: 48%;
        margin-top: 0;
    }
    .church-information h1 {
        font-size: 48px; /* 2 veces el tamaño base */
        font-weight: bold;
        margin-bottom: 1.2rem;
    }
  
    .church-information h2 {
        font-size: 40px; /* 1.67 veces el tamaño base */
        font-weight: bold;
        margin-bottom: 1.1rem;
    }
  
    .church-information h3 {
        font-size: 32px; /* 1.33 veces el tamaño base */
        font-weight: bold;
        margin-bottom: 1rem;
    }
  
    .church-information h4 {
        font-size: 28px; /* 1.17 veces el tamaño base */
        font-weight: bold;
        margin-bottom: 0.9rem;
    }
  
    .church-information h5 {
        font-size: 24px; /* igual al tamaño base */
        font-weight: bold;
        margin-bottom: 0.8rem;
    }
  
    .church-information h6 {
        font-size: 20px; /* 0.83 veces el tamaño base */
        font-weight: bold;
        margin-bottom: 0.7rem;
    }
}



