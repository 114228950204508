.structure-bg-image{
  background-image: url('/public/notes5.webp');
}

.structure-layout {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.structure-container {
  width: 100%;
  padding: 20px;
  margin-top: 0;
  padding-bottom: 5vh;
}

/* Estilos para las tarjetas */
.structure-card {
  background-color: #fff7e6;
  border-radius: 0;
  padding: 5px 0px;
  text-align: center;
  border: 3px solid #eca379;
}

.structure-card h2 {
  color: #5c0011;
  margin-top: 15px;
  font-size: xx-large;
}

.structure-card h3 {
  font-size: x-large;
}

.structure-card p {
  color: #610b00;
  margin-bottom: 0;
}

/* Estilos para la imagen */
.structure-image {
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
  height: 120px;
  width: auto;
  max-width: 120px;
}

/* Responsive: Dos tarjetas por fila en pantallas grandes */
@media (min-width: 768px) {
  .structure-card {
    width: 100%;
  }
}

/* Responsive: Una tarjeta por fila en pantallas pequeñas */
@media (max-width: 767px) {
  .structure-card {
    width: 100%;
  }
}

.structure-layout h2.structure-title {
  color: #610b00;
  font-size: xxx-large;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 4px saddlebrown solid;
  border-bottom-style: double;
  margin-left: 2.4vw;
  margin-right: 2.4vw;
}
.structure-container.no-image .ant-col{
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.structure-container.no-image .ant-card .ant-card-body{
  padding: 0;
}