.navbar {
  display: flex;
  align-items: center;
  padding: 0 20px;
  transition: top 0.3s;
  position: sticky;
  top: 0;
  z-index: 1000;
  font-weight: normal;
}

.navbar,
.navbar-menu {
  background-color: #ffffff;
}

.navbar-menu {
  text-shadow: none;
}

.navbar-title,
.navbar-menu .ant-menu-item,
.navbar-menu .ant-menu-submenu-title,
.close-button,
.anticon-menu-fold,
span.anticon.anticon-menu {
  color: #610b00;
}

span.anticon.anticon-menu {
  font-size: large;
  line-height: 8px;
}

.navbar-menu .ant-menu-item:hover,
.navbar-menu .ant-menu-submenu-title:hover,
.navbar-menu .ant-menu-item a:hover {
  color: #fa8c16;
}

.navbar-hidden {
  top: -75px;
}

.navbar-visible {
  top: 0;
}

.mobile-navbar {
  padding: 10px 0px 10px 20px;
}

.navbar-logo-title {
  flex: 1;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.navbar-logo {
  height: 45px;
  margin-right: 10px;
}

.navbar-title {
  margin: 0;
  font-size: xx-large;
  line-height: 1.2;
  font-weight: normal;
}

.navbar-menu {
  flex: 1;
  line-height: 64px;
  color: #ad4e00;
  border: 0;
  font-size: large;
}


li.ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.ant-menu-submenu{
  font-size: large;
}
.ant-drawer .ant-drawer-body {
  padding: 0;
}

.ant-drawer .ant-drawer-header {
  padding-right: 12px;
}

.ant-drawer .ant-menu-title-content {
  font-size: large;
}

.drawer-title {
  display: flex;
  align-items: center;
}

.anticon-menu-unfold {
  color: #d46b08;
}

.ant-drawer .navbar-title {
  color: #7a423e;
}

@media (max-width: 768px) {
  .navbar-hidden {
    top: -84px;
  }
}

/* Estilos para el contenedor de iglesias */
.church-menu-grid {
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  padding: 5px;
  padding-bottom: 10px;
  z-index: 1000;
  justify-content: center;
  flex-direction: column;
  text-shadow: none;
  line-height: 40px;
  margin: 9px;
  border-radius: 4px;
  background-color: #fff6e6;
  /* Estado inicial: oculto, altura y opacidad a 0 */
  height: 0;
  opacity: 0;
  overflow: hidden;
  /* Oculta el contenido cuando no está expandido */
  transition: height .4s ease, opacity 0.2s ease;
  pointer-events: none;
}

/* Estado visible */
.church-menu-grid.show {
  height: 367px;
  opacity: 1;
  pointer-events: auto;
}

.church-grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.church-grid-item {
  flex: 0 0 calc(25% - 10px);
  box-sizing: border-box;
}

.church-grid-item:hover {
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

a.church-grid-item {
  color: #610b00;
  font-size: large;
  padding-left: 15px;
}

/* Estilo para el menú */
li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-active.ant-menu-item-only-child:hover,
li.ant-menu-overflow-item.ant-menu-item span:hover {
  color: #fa8c16;
}

.ant-menu.ant-menu-sub.ant-menu-vertical{
  background-color: #fff6e6;
}
.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title{
  color: #fa8c16;
}
/* Responsive: Ajustar estilo para pantallas pequeñas */
@media (max-width: 768px) {
  .church-menu-grid {
    position: relative;
    top: 0;
    padding: 10px;
  }

  .church-grid-container {
    grid-template-columns: 1fr;
    /* Una columna en pantallas pequeñas */
  }
}
