/* Contenedor del Card debajo de la imagen */
.devotion-card-container {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 30px 40px;
}

/* Estilo del Card */
.devotion-card {
  width: 100%;
    margin: 0;
  text-align: center;
  /* Centrar el texto */
  border: 0;
  border: 3px solid #eca379;
  background-color: #fff7e6;
}

/* Estilo del título dentro del card */
.devotion-card .ant-card-head-title {
  color: #610b00;
  font-size: xxx-large;
  text-align: center;
  /* Centrar el título */
  border-bottom: 0;
  border-bottom-style: double;
  padding-bottom: 10px;
  font-weight: normal;
}

/* Texto dentro del card */
.devotion-card a {
  font-size: xx-large;
  color: #610b00;
  text-align: center;
  /* Centrar el texto */
  padding: 0 20px;
}

.ant-card .ant-card-body {
  line-height: 23px;
}

/* Font size for mobile devices */
@media (max-width: 768px) {
  .devotion-card-container {
    margin: 20px;
  }

  .devotion-card-container .ant-card .ant-card-body {
    padding: 0;
    padding-bottom: 15px;
  }
}