.our-pastors-bg-image {
  background-image: url('/public/pastor.webp');
}

.our-pastors-layout {
  display: flex;
  flex-direction: column;
}

.pastors-container {
  width: 100%;
  margin: 7vh 0;
  padding: 10px;
}

/* Estilos para las tarjetas */
.pastor-card {
  background-color: #fff7e6;
  border-radius: 0;
  padding: 20px;
  text-align: center;
  border: 3px solid #eca379;
  height: 370px;
  margin-bottom: 30px;
}

.pastor-card h2 {
  color: #5c0011;
  margin-top: 15px;
  font-size: xx-large;
  line-height: 30px;
}
.pastor-card.lang-es h2{
  margin-top: 7px;
  font-size: 30px;
}

.pastor-card h3 {
  font-size: x-large;
}
.pastor-card.lang-es h3{
  font-size: 22px;
}

.pastor-card p {
  color: #610b00;
  margin-bottom: 0;
}

/* Estilos para la imagen */
.pastor-image {
  height: auto;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
  max-height: 160px;
}

/* Responsive: Dos tarjetas por fila en pantallas grandes */
@media (min-width: 768px) {
  .pastor-card {
    width: 100%;
  }
}

/* Responsive: Una tarjeta por fila en pantallas pequeñas */
@media (max-width: 767px) {
  .pastor-card {
    width: 100%;
    height: max-content;
  }
}

.our-pastors-layout h2.pastor-title {
  color: #610b00;
  /* Color del título */
  font-size: xx-large;
  /* Tamaño del texto del título */
  text-align: center;
  /* Doble línea */
  padding-bottom: 10px;
  border-bottom: 4px saddlebrown solid;
  /* Línea inferior */
  border-bottom-style: double;
  margin-left: 2.4vw;
  margin-right: 2.4vw;
  margin-bottom: 0;
  /* Doble línea */
}
@media (max-width: 1048px) {
  .pastors-container{
    margin: 0;
    padding: 20px 0px;
  }
  .pastor-card{
    margin-bottom: 20px;
  }
  .pastor-card h2{
    font-size: 29px;
    margin-top: 10px;
  }
  .pastor-card h3{
    margin-top: 30px;
    font-size: 24px;
    margin-bottom: 0px;
  }
  
}