/* Contenedor del Card debajo de la imagen */
.faith-card-container {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 5px 40px;
}

/* Estilo del Card */
.faith-card {
    width: 100%;
    margin: 0;
    text-align: center;
    /* Centrar el texto */
    background-color: inherit;
    /* Mantener el fondo heredado */
    border: 0;
    /* Sin bordes */
}

/* Estilo del título dentro del card */
.faith-card h1 {
    color: #610b00;
    /* Color del título */
    font-size: xxx-large;
    /* Tamaño del texto del título */
    text-align: center;
    /* Doble línea */
    padding-bottom: 25px;
    /* Espaciado inferior */
}

/* Texto dentro del card */
.faith-card p {
    font-size: x-large;
    /* Tamaño de fuente grande */
    color: #5c0011;
    /* Color del texto */
    text-align: center;
    /* Justificar el texto */
    padding: 0 20px;
    /* Espaciado lateral */
    margin-bottom: 16px;
    /* Espaciado entre párrafos */
}

/* Espaciado entre líneas */
.faith-card-container .ant-card .ant-card-body {
    line-height: 23px;
    /* Espaciado entre líneas */
    padding-top: 20px;
}

/* Font size for mobile devices */
@media (max-width: 768px) {
    .faith-card-container {
        margin: 20px;
        /* Espaciado para dispositivos móviles */
    }

    .faith-card .ant-card-body {
        padding: 0;
    }

    .faith-card h1 {
        font-size: xx-large;
    }
    .faith-card p{
        line-height: 28px;
    }
}

.faith-bg-image {
    background-image: url('/public/letter.webp');
}

.faith-image {
    width: 85vw;
    height: auto;
}


@media (max-width: 768px) {
    .faith-image {
        width: 80vw;
    }
}