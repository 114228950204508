.carousel-container {
  margin: 0 20px 40px;
  /* Unificar márgenes y eliminar redundancia */
  padding: 0 20px;
  /* Mantener el padding */
}

.carousel-title {
  text-align: center;
  font-size: xxx-large;
  margin: 0 0 20px;
  /* Unificar márgenes */
  color: #610b00;
  font-weight: normal;
}

.prayer-carousel {
  max-width: 100%;
  margin: 0 auto;
  /* Centramos el carrusel */
}

.prayer-card {
  text-align: left;
  border: 3px solid #eca379;
  margin: 0 10px;
  /* Espacio horizontal para las flechas */
  padding: 10px;
  /* Limitar la altura de la tarjeta */
  overflow: hidden;
  /* Ocultar contenido que se desborde */
  position: relative;
  /* Para manejar el contenido dentro */
  height: 30vw;
  background-color: #fff7e6;

}

.prayer-card .ant-card-head-title {
  font-size: xx-large;
  color: #610b00;
  font-weight: normal;
  /* Color del título de la card */
}

.prayer-card p {
  font-size: large;
  white-space: pre-line;
  /* Mantener saltos de línea */
  margin: 10px 0px;
}


/* Estilos para los dots */
.ant-carousel .slick-dots li button {
  background: #610b00 !important;
  /* Color de los dots */
  width: 12px;
  height: 12px;
  border-radius: 50%;
  /* Dots circulares */
}

.ant-carousel .slick-dots li.slick-active button {
  background: #e0aa8b !important;
  /* Color de los dots activos */
}

.ant-carousel .slick-dots li.slick-active button,
.ant-carousel .slick-dots li button {
  top: -4px;
}

/* Estilo para las flechas */
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: #610b00 !important;
  /* Color de las flechas */
  z-index: 2;
  /* Mantener las flechas encima de las cards */
  width: 40px;
  /* Área de clic aumentada */
  height: 40px;
  /* Área de clic aumentada */
}

.ant-carousel .slick-prev {
  left: -28px;
}

.ant-carousel .slick-next {
  right: -28px;
}

/* Personalización de las flechas */
.ant-carousel .slick-prev::before,
.ant-carousel .slick-next::before {
  content: '';
  /* Limpiar el contenido predeterminado */
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  /* Usar bordes para crear flechas */
}

/* Flecha izquierda */
.ant-carousel .slick-prev::before {
  border-width: 20px 24px 20px 0;
  /* Grosor de la flecha */
  border-color: transparent #610b00 transparent transparent;
  /* Color de la flecha */
}

/* Flecha derecha */
.ant-carousel .slick-next::before {
  border-width: 20px 0 20px 24px;
  /* Grosor de la flecha */
  border-color: transparent transparent transparent #610b00;
  /* Color de la flecha */
}

/* Cambio de color al pasar el mouse */
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  color: #e0aa8b !important;
  /* Color al pasar el mouse */
}

.slick-arrow.slick-prev::after,
.slick-arrow.slick-next::after {
  content: none;
}

.ant-carousel .ant-card .ant-card-body>ol {
  padding-left: 20px;
}

.ant-card-head {
  border-bottom: 2px #e0aa8b42 solid;
}

.ant-carousel .ant-card .ant-card-body {
  padding: 0px 10px;
}
.ant-carousel .ant-card .ant-card-body iframe{
  height: max-content;
  width: max-content;
  margin-left: 15px;
}
.ant-carousel .ant-card-head {
  min-height: min-content;
  padding: 0px 5px;
  margin: 0px 5px;
  text-align: center;
}

.enableReadMore .prayer-card {
  max-height: 30vw;
  /* Limitar la altura de la tarjeta a 60vh */
  overflow: hidden;
  /* Ocultar contenido que se desborde */
  position: relative;
  /* Para manejar el contenido dentro */
}

.enableReadMore .read-more-link {
  position: absolute;
  bottom: 10px;
  /* Aumentar la distancia del botón al borde inferior */
  right: 20px;
  color: #610b00;
  font-weight: normal;
  font-size: x-large;
  cursor: pointer;
  text-shadow: #fcdcdc 1px 1px 2px;
}

.enableReadMore .content-container {
  max-height: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 9;
  -webkit-line-clamp: 9;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.carousel-container ol {
  padding-inline-start: 20px;
  font-size: large;
}


/* Ocultar flechas en móviles */
@media (max-width: 768px) {

  .ant-carousel .slick-prev,
  .ant-carousel .slick-next {
    display: none !important;
    /* Ocultar flechas en dispositivos móviles */
  }

  .ant-carousel .prayer-card {
    padding: 15px;
    /* Ajustar padding en móvil */
    margin: 0;
    /* Sin márgenes en móvil */
  }

  .prayer-card,
  .enableReadMore .prayer-card {
    padding: 10px;
    height: 80vh;
    max-height: 80vh;
  }

  .content-container {
    line-clamp: 18;
    -webkit-line-clamp: 18;
  }

  .enableReadMore .content-container {
    line-clamp: 17;
    -webkit-line-clamp: 17;
  }

  .prayer-card p {
    font-size: x-large;
  }
  .carousel-container ol{
    font-size: x-large;
    padding-inline-start: 40px;
  }
}