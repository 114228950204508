.language-switcher {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #610b00;
    font-size: medium;
    padding-left: 5px;
}

.language-icon {
    margin-right: 8px;
    color: #610b00;
}

.language-option {
    display: block;
    font-size: medium;
    color: #5c0011;
}


.drawer-language-switcher {
    padding: 10px;
    padding-left: 28px;
}