.church-request-detail {
    display: flex;
    flex-direction: column;
    padding: 20px 80px;
    align-items: center;
    color: #5c0011;
    padding-bottom: 50px;
}

.church-request-detail p {
    margin-top: 0px;
}

.church-request-detail h1 {
    text-align: center;
    color: #610b00;
    margin-bottom: 40px;
    padding-bottom: 8px;
    /*     border-bottom: 8px #e0aa8b double;
 */
}

.church-request-section {
    text-align: left;
    border: 3px solid #eca379;
    margin: 0 10px;
    /* Espacio horizontal para las flechas */
    padding: 20px;
    /* Limitar la altura de la tarjeta */
    overflow: hidden;
    /* Ocultar contenido que se desborde */
    position: relative;
    /* Para manejar el contenido dentro */
    height: max-content;
    background-color: #fef6e5;
    border-radius: 5px;
    font-size: x-large;
    padding: 4%;

}

@media (max-width: 767px) {
    .church-request-detail {
        padding: 20px 35px;
        padding-bottom: 35px;
    }

    .church-request-detail h1 {
        margin-bottom: 20px;
    }
    .church-request-section {
        padding: 7%;
    }
}