/* src/components/Donation.css */

.donation-container {
    margin: 40px 0;
    padding: 20px;
    display: flex;
    justify-content: center; /* Centrar el componente en la página */
  }
  
  .donation-card {
    border-radius: 10px;
    background-color: #fff7e6;
    border: 3px solid #eca379;
    text-align: center; /* Centrar el contenido dentro de la tarjeta */
    width: 100%;
    max-width: 800px; /* Ampliar el ancho máximo de la tarjeta */
    padding: 20px;
  }
  
  .donation-card .ant-card-head-title {
    font-size: xxx-large;
    color: #610b00;
    text-wrap: unset;
  }
  
  .donation-details {
    font-size: x-large;
    color: #610b00;
  }
  
  .donation-details p {
    display: flex;
    align-items: center;
    justify-content: center; /* Centrar los elementos en cada línea */
    margin: 5px 0;
  }
  
  .donation-details .anticon {
    margin-right: 10px;
    font-size: x-large;
  }
  
  /* Responsive para que ocupe más espacio en pantallas grandes */
  @media (min-width: 768px) {
    .donation-card {
      width: 80%; 
    }
  }
  
  /* Responsive para que ocupe todo en pantallas pequeñas */
  @media (max-width: 767px) {
    .donation-card {
      width: 100%; /* Ocupar el 100% en pantallas móviles */
    }
    .donation-card .ant-card-head-title {
      font-size: xx-large;
    }
  }
  