/* Contenedor del Card debajo de la imagen */

.constitution-bg-image {
    background-image: url('/public/letter.webp');
    height: 30vh;
}


.pdfviewer-container{
    margin: 2vw 5vw;
}

@media (max-width: 768px) {
    .pdfviewer-container{
        margin: 1vw 0vw;
        touch-action: pinch-zoom; /* Habilitar pinch-to-zoom */
    }
}

