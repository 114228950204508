/* Home.css */

/* Full screen layout */

  
  /* Background image settings */
  .background-image {
    background-size: cover;
    background-position: center;
    height: 40vh;  
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  /* Title container to center the text */
  .title-container {
    text-align: center;
  }
  
  /* Animation for the title */
  .animated-title {
    font-size: 60px; /* Default font size */
    text-shadow:3px 3px 2px #7a423e;
    font-weight: normal;
    color: #fff7e6; /* White text */
    opacity: 0;
    transform: translateY(100px); /* Start the text 100px below */
    animation: slideIn 0.6s ease-out forwards; /* Slide in with easing */
  }
  
  /* Keyframes for the slide-in animation */
  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateY(40px); /* Starting point */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* Final position */
    }
  }
  
  
  /* Font size for mobile devices */
  @media (max-width: 768px) {
    .animated-title {
      font-size: xxx-large; /* Smaller font size for mobile */
    }
  }