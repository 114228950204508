.history-timeline-bg-image {
  background-image: url('/public/re2-min.webp');
}

.timeline-container {
  padding: 20px;
  padding-top: 70px;
  max-width: 80%;
}

/* Título del timeline */
.timeline-title {
  text-align: center;
  margin-bottom: 20px;
}

/* Estilo para el círculo del año */
.year-circle {
  width: 18px;
  height: 4px;
  background-color: #e0aa8b;
  border-radius: 5px;
}

/* Estilo para la línea horizontal */
.month-circle {
  width: 8px;
  height: 8px;
  background-color: #ffd591;
  /* Color para el círculo pequeño */
  margin: 0 auto;
  border-radius: 50%;
  display: inline-block;
  /* Asegurarse de que se alinee correctamente */
}

/* Estilo para el año */
.timeline-year {
  line-height: 20px;
  font-size: xx-large;
  font-weight: normal;
  display: inline-block;
  margin-right: 8px;
  color: #610b00;
}

/* Estilo para el mes y la descripción */
.ant-timeline-item-content,
.description {
  font-size: 28px;
  display: inline-block;
  /* Para alineación */
  line-height: 28px;
  color: #5c0011;
  top: 2px;
  position: relative;
}

/* Alineación de los círculos */
.ant-timeline-item-head.ant-timeline-item-head-custom.ant-timeline-item-head-blue {
  background-color: transparent;
}

.timeline-month {
  line-height: 24px;
  font-size: 24px;
}

@media (max-width: 768px) {
  .timeline-container {
    padding-top: 70px;
    max-width: 100%;
    position: relative;
    right: 12vw;
  }

  .timeline-year {
    font-size: 24px;
  }

  .timeline-month,
  .ant-timeline-item-content,
  .description {
    font-size: 20px;
  }
}